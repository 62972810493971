<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <div>
            <aside class="page__sidebar">
              <div class="sidebar__header">
                <h4 class="sidebar__title">Acertos com coletores</h4>
              </div>
              <div style="border: none;" class="sidebar__header">
                <div style="width: 100%;" class="box-ballance">
                  <span>Saldo coletor</span>
                  <br>
                  <span>R$ {{ ballance }}</span>
                </div>
              </div>
              <div style="border: none;" class="sidebar_content">
                  <button @click="openModal('transfer')" style="float: left;" class="button_green">$ Transferir </button>
                  <button @click="openModal('receiver')" class="button_red">$ Resgatar </button>
              </div>
              <div class="sidebar_content">
                <h4>Escolha o coletor:</h4>

                <select
                  name="user"
                  id="user"
                  class="select_form"
                  v-model="collectorId"
                >
                  <option
                    v-for="collector in collectors"
                    :key="collector.index"
                    :value="collector.id"
                  >
                    {{ collector.name }}
                  </option>
                </select>
                <br>
                <br>
                <h4>Escolha o material:</h4>

                <select
                  name="user"
                  id="user"
                  class="select_form"
                  v-model="materialId"
                  :disabled="materials.length < 1"
                  >
                  <option
                    :key="null"
                    :value="null"
                  >
                    Todos
                  </option>
                  <option
                    v-for="collector in materials"
                    :key="collector.id"
                    :value="collector.id"
                  >
                    {{ collector.name }}
                  </option>
                </select>
              </div>
              <div style="overflow: auto; max-height: 300px;">
                <div v-for="material in materialsList" :key="material.item_id" class="sidebar_total">
                  <p>
                    <span class="circle_initial" style="background-color: #16b666;">{{Array.from(material.name)[0]  }}</span>
                      {{material.name}}
                  </p>
                  <br>
                  <div class="collect_total">
                    <h4 class="">Quantidade:</h4>
                    <p>{{ material.ammount }}</p>
                  </div>
                  <div class="collect_total">
                    <h4 class="">Total pago:</h4>
                    <p>R$ {{ Number.parseFloat(material.total).toFixed(2) }}</p>
                  </div>
                  <div class="collect_total">
                    <h4 class="">Valor médio:</h4>
                    <p>R$ {{ Number.parseFloat(material.media).toFixed(2) }}</p>
                  </div>
                </div>
              </div>
              <div class="sidebar__nav"></div>
            </aside>
          </div>
          <div class="page__content">
            <div class="tabs__flex">
              <div class="tabs__nav">
                <a
                  class="tabs__anchor"
                  :class="{ current: filter === '' }"
                  @click="filter = ''"
                  href="#"
                  title="Aberto"
                >
                  Aberto
                </a>
                <a
                  class="tabs__anchor"
                  :class="{ current: filter === 'closed' }"
                  href="#"
                  @click="filter = 'closed'"
                  title="Fechado"
                >
                  Fechado
                </a>
              </div>

              <div v-show="filter==='closed'" class="tabs__filters">
                <div class="tabs__filters--group">
                  <label class="tabs__filters--label">De</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                    v-model="startDate"
                  />
                </div>

                <div class="tabs__filters--group">
                  <label class="tabs__filters--label">Até</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                    v-model="endDate"
                  />
                </div>
              </div>
            </div>
            <div class="tab__body current" id="all">
              <table class="table table_hits">
                <thead>
                  <tr>
                    <th style="text-align: center; width: 300px;">Material</th>
                    <th style="text-align: center;">Acerto</th>
                    <th style="text-align: center;">Quantidade</th>
                    <th style="text-align: center;">Un.</th>
                    <th style="text-align: center;">Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="hit in table" :key="hit.item_id">
                    <td style="width: 300px; text-align: center; display: table-cell; padding: 5px 0;" class="flex-td">
                      <span
                        class="circle_initial"
                        style="background-color: #16b666;"
                      >
                        {{ hit.name[0] }}
                      </span>
                      {{ hit.name }}
                    </td>
                    <td style="text-align: center;">
                      <span> {{ hit.date === undefined ? '' :  hit.date}} </span>
                    </td>
                    <td style="text-align: center;">
                      <span>{{ hit.ammount}}</span>
                    </td>
                    <td style="text-align: center;">
                      <span>{{ hit.unity }}</span>
                    </td>
                    <td style="text-align: center;">
                      <div
                        class="alert_card"
                        :class="filter === 'closed' ? 'green': 'orange'"
                      >
                        {{ filter === 'closed' ? 'Fechado': 'Aberto' }}
                      </div>
                    </td>
                    <td style="text-align: center;">
                      <a type="button" class="edit" title="Imprimir certificado" :href=" 'details?id=' + hit.orders.join('-')">
                        <i class="fa fa-search"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <modal-register :operation="operation" :collector="collectorId" :modal.sync="modal" @change-modal="e => (modal = e)" :editing.sync="editing"></modal-register>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { mapActions } from 'vuex'
import swal from 'sweetalert'
const defaultForm = {
  id: '',
  type: 'PF',
  city_id: null,
  province_id: null,
  name: '',
  email: '',
  phone: '',
  company_id: 1
}
export default {
  data: () => ({
    form: { ...defaultForm },
    error: null,
    load: true,
    modal: false,
    editing: {},
    msg: null,
    hits: [],
    province_id: '',
    collectors: [],
    collectorId: '',
    filter: '',
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    tab: '',
    materials: [],
    materialId: null,
    materialsList: [],
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
    ballance: '-',
    table: [],
    operation: ''
  }),
  components: {
    modalRegister: () => import('./components/modal-register-collector')
  },
  computed: {
    collectorName () {
      const [collector] = this.collectors.filter(i => i.id === this.collectorId)
      return collector ? collector.name : '-'
    },
    monthYear () {
      const date = new Date(Date.UTC(this.year, this.month))
      const formated = new Intl.DateTimeFormat('pt-BR', {
        dateStyle: 'full'
      }).format(date)
      const [year, month] = formated.split(' de ').reverse()
      return `${month} de ${year}`
    }
  },
  methods: {
    ...mapActions('collector', { fetchCollectors: 'fetch' }),
    ...mapActions('client', ['fetch']),

    openEditModal (item = null) {
      this.editing = {}
      if (!item) return
      const itemToEdit = { ...item }
      this.editing = itemToEdit
      this.modal = true
    },
    async transfer (value) {
      const confirmActivation = await swal({
        title: 'Input email address',
        input: 'text',
        inputLabel: 'Your email address',
        inputPlaceholder: 'Enter your email address'
      })

      if (confirmActivation) {
        try {
          value.status = value.status === 1 ? 3 : 1

          const payload = {
            id: value.id,
            zip_code: value.address.cep,
            district: value.address.district,
            complement: value.address.complement,
            street: value.address.street,
            city_id: value.address.city_id,
            province_id: value.address.province_id,
            name: value.name,
            phone: value.data.phone,
            collector_type: value.company_roles[0].role.id,
            email: value.email,
            number: value.address.number,
            status: value.status
          }

          if (value.type === 'PJ') {
            payload.cnpj = value.data.cnpj
          } else {
            payload.cpf = value.data.cpf
          }
          const response = await this.$http.put('/collectors/users', payload)
          if (response.status === 200) {
            await swal({
              title: value.status === 1 ? 'Usuário ativado com sucesso!' : 'Usuário desativado com sucesso!',
              icon: 'success'
            })
            await this.getData()
          }
        } catch (err) {
          await swal('Oops!', 'Erro ao desativar usuário', 'error')
        }
      }
    },
    async deleteClient (item = null) {
      const url = 'clients/destroy'

      this.$http.delete(url, { body: { id: item } }).then(response => {
        this.msg = response.msg
      })
    },
    openModal (operation) {
      this.modal = true
      this.operation = operation
    },
    changeDate (to) {
      let { month, year } = this
      if (to === 'next') month++
      else if (to === 'prev') month--
      if (month === 0) {
        month = 12
        year--
      } else if (month === 13) {
        month = 1
        year++
      }
      this.month = month
      this.year = year
    },
    async getHits () {
      if (this.collectorId !== '') {
        const filter = this.filter === '' ? 'open' : this.filter
        const { startDate, endDate, collectorId, materialId } = this

        let url = 'check/hitsCollector?page=1'
        if (materialId !== null && materialId !== 0 && materialId !== '0' && materialId !== undefined && materialId !== '' && materialId !== 'null') url += `&materialId=${materialId}`
        if (filter === 'closed') url += `&startDate=${startDate}&endDate=${endDate}`
        url += `&collector_id=${collectorId}&status=${filter}`
        const { data } = await this.$http.get(url)
        this.materials = data.data.materials
        this.ballance = data.data.ballance
        this.materialsList = data.data.total_materials
        this.hits = data.data
        this.table = data.data.table
      }
    },
    async getCollectors () {
      const url = 'check/getCollectors'
      const { data } = await this.$http.post(url)
      this.collectors = data.data
      const [first] = this.collectors
      if (!first) return
      this.collectorId = first.id
    }
  },
  created () {
    this.getCollectors()
  },
  mounted () {
    this.getHits()
    this.getCollectors()
  },
  filters: {
    personTypeColor (val) {
      if (val === 'PF') return 'background-color: #f3e1fd; color: #9532e3;'
      return 'background-color: #DFF5FF; color: #2976E4;'
    },
    personType (val) {
      const types = { PF: 'Pessoa Física', PJ: 'Pessoa Jurídica' }
      return types[val]
    },
    statusColor (val) {
      const status = {
        A: {
          'background-color': 'rgb(255, 230, 224)',
          color: 'rgb(255, 55, 26)'
        },
        F: {
          'background-color': 'rgb(224, 248, 230)',
          color: 'rgb(22, 182, 102)'
        }
      }
      return status[val]
    },
    statusLabel (val) {
      const status = { A: 'Aberto', F: 'Fechado' }
      return status[val]
    }
  },
  watch: {
    collectorId (val, old) {
      if (val === old) return
      this.getHits()
    },
    materialId (val, old) {
      if (val === old) return
      this.getHits()
    },
    startDate (val, old) {
      if (val === old && !this.endDate) return
      this.getHits()
    },
    endDate (val, old) {
      if (val === old && !this.startDate) return
      this.getHits()
    },
    month (val, old) {
      if (val === old) return
      this.getHits()
    },
    filter (val, old) {
      if (val === old) return
      this.getHits()
    }
  }
}
</script>

<style lang="scss" scoped>

.button_green{
  background-color: #ddebe1;
  padding: 5px;
  border-radius: 6px;
  color: #16b666;
  margin-right: 10px;
  font-size: 13px;
  width: 145px;
  display: block;
  height: 30px;
}

.green{
  color: green;
  font-size: 14px;
  background-color: #beffde;
}

.orange{
  color:  #d87420;
  background-color:rgb(253, 231, 190);
  font-size: 14px
}

.button_red{
  background-color: #ebdddf;
  padding: 5px;
  border-radius: 6px;
  color: #b63616;
  font-size: 13px;
  width: 145px;
  height: 30px;
}

.box-ballance{
  border: 1px solid #e9e9e9;
  padding: 5px;
  box-shadow: 5px 5px 10px #e5e4e4;
}

.box-ballance > span:first-child{
  font-size: 12px;
}

.box-ballance > span:last-child{
  font-size: 18px;
  color: green;
}
.change-date {
  cursor: pointer;
}

.sidebar_content {
  display: block;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.table {
  table-layout: fixed;
}

th,
td {
  font-weight: 400;
  width: 100%;
  text-align: left;
}

td span {
  font-weight: 400;
  font-size: 16px;
}

tr {
  border-bottom: 1px solid #e5e5e5;
}

.table tbody tr td:nth-child(1) {
  width: 200px;
  display: inline-flex;
}

.table tbody tr td:nth-child(3) {
  text-align: center;
}

.alert {
  background-color: #fff3e2;
  color: #d87420;
  padding: 12px;
  border-radius: 7px;
}

.alert_card {
  font-size: 10pt;
  text-align: center;
  padding: 3px;
  border-radius: 4px;
}

.circle_initial {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  margin-right: 7px;
  position: relative;
}

.circle_initial p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.header_content {
  display: flex;
  justify-content: space-between;
}

.collect_name {
  display: inline-flex;
}

.collect_name h3 {
  margin-right: 5px;
}

.collect_name p {
  font-size: 15pt;
}

.months {
  display: inline-flex;
}

.months i {
  color: #16b666;
}

.months p {
  margin-left: 15px;
  margin-right: 15px;
}

.tabs__nav {
  margin-top: 45px;
}

.table_hits {
  margin-top: 5px;
}

.collect_total {
  display: flex;
  margin-bottom: 20px;
}

.collect_total h4 {
  margin-right: 12px;
}

.collect_total p {
  font-size: 12pt;
}

.circle_initial {
  width: 30px;
  height: 30px;
  display: inline-flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 7px;
  position: relative;
}

.flex-td {
  display: flex;
  align-items: center;
}

.sidebar_total {
  margin-bottom: 2rem;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 0;

  &:last-child {
    border-bottom-color: none;
  }

  .collect_total:first-child {
    color: #000;
  }
}
</style>
